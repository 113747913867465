<template>
  <div>
    <div
      v-if="!groupName"
      v-show="isBasicFilterShow(index) || isAdvanceFilterShow(index)"
    >
      <CListGroupItem
        class="py-2 border-1"
        href="javascript:"
        @click="showCollapse(index)"
      >
        <div class="d-flex justify-content-between align-items-center">
          <p class="m-0">{{ value.label }}</p>
          <button class="btn shadow-none">
            <i
              :class="`fa fa-${collapseIndex === index ? 'minus' : 'plus'}`"
            ></i>
          </button>
        </div>
      </CListGroupItem>
      <CCollapse :show="collapseIndex === index">
        <CCard class="mx-0 my-0 rounded-0" body-wrapper>
          <component
            :is="value.component"
            :key="index"
            :name="value.name"
            :label="''"
            :placeholder="value.placeholder"
            :rangeValue="getSelectedRangeValue"
            :selectedValue="selectedFilters[propertyName]"
            :value="selectedFilters[propertyName]"
            @input="onInput"
            :onInput="onInput"
            :options="getDependentOptions(propertyName)"
            :taggable="value.taggable"
            :multiple="value.multiple"
            :clearable="value.clearable"
            :disabled="isDisable(propertyName)"
            :fetchOptions="fetchOptions"
            :accordionFetchOption="accordionFetchOption"
            :enableNA="true"
            :specialitySmartOptions="specialitySmartOptions"
            :inputFields="value.inputFields"
            :autoPopulate="value.autoPopulate"
            :futureYear="value.futureYear"
            :numberOfYears="value.numberOfYears"
            :startYear="value.startYear"
            :showCount="value.showCount"
          />
        </CCard>
      </CCollapse>
    </div>
    <component
      v-else
      :is="value.component"
      :key="index"
      :name="value.name"
      :label="value.label"
      :placeholder="value.placeholder"
      :rangeValue="getSelectedRangeValue"
      :selectedValue="selectedFilters[propertyName]"
      :value="selectedFilters[propertyName]"
      @input="onInput"
      :onInput="onInput"
      :options="getDependentOptions(propertyName)"
      :taggable="value.taggable"
      :multiple="value.multiple"
      :clearable="value.clearable"
      :disabled="isDisable(propertyName)"
      :fetchOptions="fetchOptions"
      :accordionFetchOption="accordionFetchOption"
      :enableNA="true"
      :specialitySmartOptions="specialitySmartOptions"
      :inputFields="value.inputFields"
      :autoPopulate="value.autoPopulate"
      :futureYear="value.futureYear"
      :numberOfYears="value.numberOfYears"
      :startYear="value.startYear"
      :showCount="value.showCount"
    />
  </div>
</template>

<script>
import Vue from "vue";
import filterSelect from "@/components/reusable/Fields/GeniricFilterSelect";
import customSelectOption from "@/components/reusable/Fields/CustomSelectOption";
import accordionSelect from "@/components/reusable/Fields/accordionSelect.vue";
import Range from "@/components/reusable/Fields/Range";
import datePicker from "../../../components/reusable/Fields/FilterDatePicker";
import dateInput from "@/components/reusable/Fields/FilterDateInput";
import FilterCheckBox from "@/components/reusable/Fields/FilterCheckBox";

Vue.component("filterSelect", filterSelect);
Vue.component("customSelectOption", customSelectOption);
Vue.component("accordionSelect", accordionSelect);
Vue.component("filterRange", Range);
Vue.component("filterDatePicker", datePicker);
Vue.component("filterDateInput", dateInput);
Vue.component("filterCheckBox", FilterCheckBox);

export default {
  props: [
    "basicFilterlength",
    "index",
    "isAdvancedFilterVisible",
    "collapseIndex",
    "showCollapse",
    "value",
    "selectedFilters",
    "propertyName",
    "getDependentOptions",
    "isDisable",
    "onInput",
    "groupName",
    "fetchOptions",
    "accordionFetchOption",
    "specialitySmartOptions",
  ],
  computed: {
    isBasicFilterShow() {
      return (index) => {
        if (!this.basicFilterlength) {
          return true;
        }
        return this.basicFilterlength > index;
      };
    },
    getSelectedRangeValue() {
      if (
        Array.isArray(this.selectedFilters[this.propertyName]) &&
        this.selectedFilters[this.propertyName].length
      ) {
        return this.selectedFilters[this.propertyName][0];
      }
      return 20;
    },
    isAdvanceFilterShow() {
      return (index) => {
        return (
          this.basicFilterlength &&
          this.basicFilterlength <= index &&
          this.isAdvancedFilterVisible
        );
      };
    },
  },
};
</script>
