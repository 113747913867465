<template>
  <!-- showing selected filter tags start-->
  <div>
    <div
      v-for="(value, filterType, typeIndex) in customOrder(onlySelectedFilters)"
      :key="typeIndex"
      class="mt-2 filter-details d-inline-block"
      :class="{ 'w-100': filterType === 'specialitySearch' }"
    >
      <div class="filter-details-item d-flex vs__selected-options">
        <span>{{ getLabel(filterType) }} :</span>
        <div
          v-for="(filterValue, valueIndex) in groupFilterData(
            value,
            filterType
          )"
          :key="valueIndex"
          class="vs__selected m-0 ml-1"
        >
          <span
            v-if="filterType == 'specialitySearch'"
            class="rtx-40"
            :class="showColorCode(filterValue.children)"
          >
            {{ filterValue.label }}
            <button
              type="button"
              aria-label="Deselect option"
              class="vs__deselect"
              @click="removeFilter(filterType, filterValue)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                <path
                  d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                />
              </svg>
            </button>
          </span>
          <span v-else class="rtx-40"
            >{{ filterValue.label || filterValue }}

            <button
              type="button"
              aria-label="Deselect option"
              class="vs__deselect"
              @click="removeFilter(filterType, filterValue)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                <path
                  d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>
      <div
        class="toggle cursor-pointer filter-details-item d-flex ml-1"
        v-if="filterType == 'specialitySearch'"
      >
        <span @click="showSubCategory">{{
          isShowSubCategory ? "Show less" : "Show more"
        }}</span>
      </div>
      <div class="filter-details-item detailContainer" v-if="isShowSubCategory">
        <div
          v-for="(filterValue, valueIndex) in groupFilterData(
            value,
            filterType
          )"
          :key="valueIndex"
          class="m-0 ml-1 w-100 d-flex"
        >
          <div v-if="filterType == 'specialitySearch'">
            <h6>{{ filterValue.label }}</h6>
            <div
              v-for="(value, Index) in filterValue.children"
              :key="Index"
              class="m-0 ml-1 vs__selected float-left"
            >
              <span>{{ value.label }}</span>
              <button
                type="button"
                aria-label="Deselect option"
                class="vs__deselect"
                @click="removeFilter(filterType, value)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                  <path
                    d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- showing selected filter tags end -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: ["onlySelectedFilters", "currentPageSchema", "clearCallback"],
  data() {
    return {
      isShowSubCategory: false,
    };
  },
  methods: {
    customOrder(data) {
      if (data) {
        let newObj = {};
        Object.keys(data)
          .sort((a, b) => (b === "specialitySearch" ? -1 : 0))
          .forEach((key) => (newObj[key] = data[key]));
        return newObj;
      }
      return data;
    },
    removeFilter(type, val) {
      let selectedFiltersData = this.selectedFilters[type];
      let payload = {};
      if (type == "searchTerm") {
        payload = { name: type, value: [] };
      } else if (this.currentPageSchema[type]["component"] === "filterRange") {
        payload = { name: type, value: "" };
      } else if (
        Array.isArray(selectedFiltersData) &&
        selectedFiltersData.length
      ) {
        if (type == "specialitySearch") {
          payload = {
            name: type,
            value: selectedFiltersData.filter(
              (value) => !val.code.includes(value.code)
            ),
          };
        } else {
          payload = {
            name: type,
            value: selectedFiltersData.filter(
              (value) => val.code !== value.code
            ),
          };
        }
      } else if (
        isObject(selectedFiltersData) &&
        !isEmptyObjectCheck(selectedFiltersData)
      ) {
        payload = { name: type, value: {} };
      }
      this.clearCallback(payload.name, payload.value);
    },
    groupFilterData(options, type) {
      if (type == "specialitySearch") {
        let r =
          options &&
          _.reduce(
            options,
            function (result, value, key) {
              result && result.length ? null : (result = []);
              if (value?.speciality) {
                let _groupByindex = _.findIndex(result, {
                  label: value?.speciality,
                });
                let code = value?.code;
                let country_code = null;
                let speciality = value?.speciality;
                let speciality_id = value?.speciality_id;
                let speciality_reference_group = null;
                let sub_speciality = value?.sub_speciality;
                let sub_speciality_id = value?.sub_speciality_id;

                const cCode = value?.speciality_reference_group
                  ? value?.speciality_reference_group
                  : value?.code;
                if (_groupByindex < 0) {
                  let opt = {
                    code,
                    country_code,
                    id: code,
                    label: value?.speciality,
                    speciality,
                    speciality_id,
                    speciality_reference_group,
                    sub_speciality,
                    sub_speciality_id,
                    children: [
                      {
                        code,
                        country_code,
                        id: value?.code,
                        label: sub_speciality,
                        speciality,
                        speciality_id,
                        speciality_reference_group,
                        sub_speciality,
                        sub_speciality_id,
                      },
                    ],
                  };
                  result.push(opt);
                } else {
                  if (!result[_groupByindex].code.includes(cCode))
                    result[_groupByindex].code += `,${cCode}`;
                  let _Cindex = _.findIndex(result[_groupByindex].children, {
                    label: speciality,
                  });
                  if (_Cindex < 0) {
                    let ccData = {
                      code,
                      country_code,
                      id: value?.code,
                      label: sub_speciality,
                      speciality,
                      speciality_id,
                      speciality_reference_group,
                      sub_speciality,
                      sub_speciality_id,
                    };

                    result[_groupByindex].children.push(ccData);
                  } else {
                    if (!result[_groupByindex].children) {
                      let _Cvalue = result[_groupByindex];
                      let _cId = value?.speciality_reference_group
                        ? value?.speciality_reference_group
                        : _Cvalue.id;
                      result[_groupByindex] = {
                        id: _cId,
                        label: sub_speciality,
                        children: [
                          {
                            code: _Cvalue.code,
                            country_code: _Cvalue.country_code,
                            id: _Cvalue.id,
                            label: _Cvalue.sub_speciality,
                            speciality: _Cvalue.speciality,
                            speciality_id: _Cvalue.sub_speciality_id,
                            speciality_reference_group:
                              _Cvalue.speciality_reference_group,
                            sub_speciality: _Cvalue.sub_speciality,
                            sub_speciality_id: _Cvalue.sub_speciality_id,
                          },
                        ],
                      };
                    }
                    result[_groupByindex].children.push({
                      code,
                      country_code,
                      id: value?.code,
                      label: sub_speciality,
                      speciality,
                      speciality_id,
                      speciality_reference_group,
                      sub_speciality,
                      sub_speciality_id,
                    });
                  }
                }
              }
              return result;
            },
            []
          );
        return r;
      }
      return options;
    },
    showSubCategory() {
      this.isShowSubCategory = !this.isShowSubCategory;
    },
    showColorCode(data) {
      if (data[0].label == "All") return "green";
      else if (data.length > 1) return "yellow";
    },
  },
  computed: {
    ...mapGetters(["selectedFilters"]),
    getLabel() {
      return (filterType) => {
        if (filterType == "searchTerm") {
          return "Search Term";
        }
        if (
          this.currentPageSchema[filterType] &&
          this.currentPageSchema[filterType]["label"]
        ) {
          return this.currentPageSchema[filterType]["label"];
        }
        return filterType;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  text-decoration: underline;
  color: rgb(167, 26, 26);
  font-size: 10px;
}
.detailContainer {
  background: #fff;
  box-shadow: -1px 1px 5px 0px #888888;
  margin: 1px;
  width: 97%;
  h6 {
    margin: 6px;
  }
  .vs__selected {
    margin-bottom: 6px !important;
  }
}

.green {
  background-color: #d0f2d2;
}
.yellow {
  background-color: #f9f2da;
}
.red {
  background-color: #f9dada;
}
.rtx-40 {
  padding: 0 0.25em;
  border-radius: 3px;
}
.vue-treeselect__multi-value-item-container {
  display: none !important;
}
</style>
