<template>
  <CCard
    v-if="item"
    name="job-card"
    :id="`job-card-${item.job_id}`"
    class="candidate-card border-primary"
    style="min-height: 394px"
  >
    <div class="justify-content-between p-2 dark-block">
      <CRow>
        <CCol col="3" class="image-holder">
          <img
            :src="iconUrl"
            alt="logo"
            width="100%"
            @error="$event.target.src = '/img/hospital.png'"
            class="rounded-circle mr-1 ratio-1"
          />
          <span
            class="p-0 btn-link fs-10 font-weight-bold text-nowrap"
            @click="navigateToJob(item)"
          >
            <span v-if="isTFWCustomerFromAccessToken">Job ID : {{item.customer_uid}} - {{ displayId }}</span>
            <span v-else>Job ID : {{ displayId }}</span>
          </span>
          <span class="ml-2" v-if="item.is_private_job" style="color: #696969">
            <em
              v-c-tooltip="{
                content: 'Private Job',
                appendToBody: true,
              }"
              style="font-size: 12px"
              class="fas fa-lock"
            >
            </em>
          </span>
        </CCol>
        <CCol col="9" class="position-relative ad-loc">
          <CRow>
            <CCol col="6">
              <h6>
                {{ jobTitle }}
              </h6>
            </CCol>
            <CCol
              col="4"
              class="p-0 cursor-pointer"
              v-if="
                !isJobSupplierFromAccessToken &&
                !isCandidateSupplierFromAccessToken
              "
            >
              <p>
                <em
                  v-if="publishedTo.includes(CONST.HOSPITAL_JOB_BOARD)"
                  v-c-tooltip="'Posted in Hospital Job Board'"
                  class="fa fa-hospital"
                />
                <em
                  v-if="publishedTo.includes(CONST.GROUP_JOB_BOARD)"
                  v-c-tooltip="'Posted in Group Job Board'"
                  class="fa fa-people-group ml-1"
                />
                <em
                  v-if="publishedTo.includes(CONST.SOCIAL_MEDIA)"
                  v-c-tooltip="'Posted in Social Media'"
                  class="fas fa-rectangle-ad ml-1"
                />
                <em
                  v-if="item.parent_org_info_required"
                  v-c-tooltip="'Parent branding enabled'"
                  class="fas fa-building-user ml-1"
                />
              </p>
            </CCol>
            <CCol col="2" class="p-0 cursor-pointer">
              <em
                v-c-tooltip="'Groups'"
                class="fas fa-group-arrows-rotate ml-1"
                @click="openGroupList(item)"
              />
            </CCol>
          </CRow>
          <p class="d-inline-block">
            <span class="icon-location"></span>
            {{ location }}&nbsp;
            <p v-if="displayLocation" class="d-inline-block">{{ `| `+ displayLocation }}</p>
          </p>
          <p
            class="mb-0 text-right position-absolute"
            style="bottom: 0; right: 15px"
            v-if="isJobCreatedByParent"
          >
            Created by:&nbsp;<strong>{{ isJobCreatedByParent }}</strong>
          </p>
        </CCol>
      </CRow>
    </div>
    <div class="gray-block">
      <CRow>
        <CCol lg="12">
          <span>{{ organisation }}</span>
        </CCol>
      </CRow>

      <CRow v-if="!isCandidateSupplierFromAccessToken">
        <CCol col="4" :name="`job-status-${item.job_id}`">
          <div
            v-if="item.status == 'Active' && !item.is_private_job"
            class="status"
            style="color: green"
          >
            <em class="fas fa-check-circle"></em>
            {{ publishStatus }}
          </div>
          <div
            v-else-if="item.status == 'Inactive'"
            class="status"
            style="color: red"
          >
            <em class="fas fa-times-circle"></em>
            {{ publishStatus }}
          </div>
          <div
            v-else-if="item.status == 'Archive'"
            class="status"
            style="color: red"
          >
            <em class="fas fa-times-circle"></em>
            {{ publishStatus }}
          </div>
          <div
            v-else-if="item.status == 'Pending'"
            class="status"
            style="color: orange"
          >
            <em class="fas fa-business-time"></em>
            {{ publishStatus }}
          </div>
          <div
            v-else-if="item.status == 'Draft' || item.is_private_job"
            class="status"
            style="color: grey"
          >
            <em class="fas fa-archive"></em>
            {{ publishStatus }}
          </div>
        </CCol>
        <CCol col="8" style="text-align: right">
          <div class="status">
            {{ formatDate(item.expiry_review_date) }} | Expires:
            {{ expiryDays }}
          </div>
          <div
            v-if="
              isUKMainAgency &&
              item.allocated_organisation_count &&
              item.allocated_organisation_count > 0
            "
            @click="showAllocatedAgencies(item)"
            :class="{
              'cursor-pointer': item.allocated_organisation_count > 0,
            }"
          >
            Allocated:
            <span
              class="step bg-primary mt-1"
              v-c-tooltip="{
                content: 'Allocated Agencies',
                placement: 'top',
                appendToBody: true,
              }"
              >{{ item.allocated_organisation_count }}</span
            >
          </div>
        </CCol>
      </CRow>
    </div>

    <CCardBody class="content-block">
      <div class="d-flex flex-column justify-content-between">
        <div class="details">
          <CRow
            v-if="
              isUKMainAgency &&
              showAllocatedOrg &&
              getAllocatedOrgsForJob &&
              getAllocatedOrgsForJob.job_id == item.job_id &&
              getAllocatedAgencies &&
              getAllocatedAgencies.length
            "
            class="allocated-agency"
          >
            <CCol class="lable" col="5"> Allocated Agencies </CCol>
            <CCol
              v-if="getAllocatedAgencies.length > 27"
              v-c-tooltip="{
                content: getNumberedAllocatedAgencies,
              }"
              class="value text-truncate"
              col="7"
            >
              <span>{{ getAllocatedAgencies }}</span>
            </CCol>
            <CCol v-else class="value" col="7">
              {{ getAllocatedAgencies }}
            </CCol>
          </CRow>
          <CRow>
            <CCol class="lable" col="5"> Type </CCol>
            <CCol class="value" col="7">
              {{ type }}
            </CCol>
          </CRow>

          <CRow>
            <CCol class="lable" col="5"> Requirement </CCol>
            <CCol
              v-if="level.length > 27"
              v-c-tooltip="{
                content: level,
              }"
              class="value text-truncate"
              col="7"
            >
              {{ level }}
            </CCol>
            <CCol v-else class="value" col="7">
              {{ level }}
            </CCol>
          </CRow>

          <CRow v-if="!isAHP">
            <CCol class="lable" col="5">
              {{ isNonClinicalAdmin ? `Main Expertise` : `Speciality` }}
            </CCol>
            <CCol
              v-if="speciality.length > 27"
              v-c-tooltip="{
                content: speciality,
              }"
              class="value text-truncate"
              col="7"
            >
              {{ speciality }}
            </CCol>
            <CCol v-else class="value" col="7">
              {{ speciality }}
            </CCol>
          </CRow>

          <CRow v-if="!isNurse && !isNonClinicalAdmin">
            <CCol class="lable" col="5">
              {{ isAHP ? `Sub Type` : `Sub Speciality` }}</CCol
            >
            <CCol
              v-if="subSpeciality.length > 27"
              v-c-tooltip="{
                content: subSpeciality,
              }"
              class="value text-truncate"
              col="7"
            >
              {{ subSpeciality }}
            </CCol>
            <CCol v-else class="value" col="7">
              {{ subSpeciality }}
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol class="lable" col="5">
              {{ isNurse ? "Other Expertise" : "Other area of Expertise" }}
            </CCol>
            <CCol
              v-if="other_areas_of_expertise.length > 27"
              v-c-tooltip="{
                content: other_areas_of_expertise,
              }"
              class="value text-truncate"
              col="7"
            >
              {{ other_areas_of_expertise || "--" }}
            </CCol>
            <CCol v-else col="7">
              {{ other_areas_of_expertise || "--" }}
            </CCol>
          </CRow>
          <CRow v-if="isCandidateSupplierFromAccessToken">
            <CCol class="lable" col="5"> Allocated on </CCol>
            <CCol class="value" col="7">
              {{ formatDate(jobAllocatedOn) }}
            </CCol>
          </CRow>
        </div>

        <CRow
          v-if="!isCandidateSupplierFromAccessToken"
          class="content-center text-muted"
        >
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">
                  {{ totalAppliedCount }}({{ totalAppliedMatchedCount }})
                </span>
              </a>
              <p class="m-0">Total Applied</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ matchedCount }}</span>
              </a>
              <p class="m-0">Matched</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ inProcessCount }}</span>
              </a>
              <p class="m-0">In Process</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a
                @click="navigateToJobListPreview(item)"
                class="text-bold"
                style="color: red"
              >
                0
              </a>
              <p class="m-0" style="color: red">Alerts</p>
            </div>
          </CCol>
        </CRow>
        <CRow
          v-if="isCandidateSupplierFromAccessToken"
          class="content-center text-muted"
        >
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ matchedCount }}</span>
              </a>
              <p class="m-0">Matched</p>
            </div>
          </CCol>
          <CCol lg="6">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ agencyMatchedCount }}</span>
              </a>
              <p class="m-0">Manual Match</p>
            </div>
          </CCol>
          <CCol lg="12">
            <div class="card-block">
              <a @click="navigateToJobListPreview(item)" class="text-bold">
                <span class="match-span">{{ inProcessCount }}</span>
              </a>
              <p class="m-0">In Process</p>
            </div>
          </CCol>
        </CRow>
      </div>
    </CCardBody>

    <div class="d-flex justify-content-center pb-2">
      <CButton
        name="preview-job-btn"
        :id="`preview-job-${item.job_id}`"
        class="mx-2 action-btn btn-outline-primary"
        @click="previewJob(item)"
      >
        Preview Job</CButton
      >
      <CButton
        v-if="!item.doh_candidate_status"
        name="view-job-btn"
        :id="`view-job-${item.job_id}`"
        class="mx-2 action-btn btn-outline-primary"
        @click="navigateToJobListPreview(item)"
      >
        View Job</CButton
      >
    </div>
    <JobCardPreviewModal
      v-if="previewModal"
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
    <job-group-modal
      :modalTitle="jobGroupModal.modalTitle"
      :modalColor="jobGroupModal.modalColor"
      :isShowPopup="jobGroupModal.isShowPopup"
      :jobData="item"
      :closeModal="closeModal"
      :modalCallback="jobGroupModalCallback"
      v-if="jobGroupModal.isShowPopup"
    />
  </CCard>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import { CONSTANT as CONST } from "@/helpers/helper";
import JobGroupModal from "@/components/reusable/JobGroupModal";

export default {
  props: ["item"],
  components: {
    JobCardPreviewModal,
    JobGroupModal,
  },
  data() {
    return {
      CONST,
      previewModal: false,
      showAllocatedOrg: false,
      jobGroupModal: {
        isShowPopup: false,
        modalTitle: "Job Group",
        modalColor: "primary",
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getRoleScopes",
      "isCandidateSupplierFromAccessToken", // pertemps
      "isUKMainAgency", // pertemps
      "getAllocatedOrgsForJob",
      "isJobSupplierFromAccessToken",
      "isTFWCustomerFromAccessToken",
    ]),
    getAllocatedAgencies() {
      return this.getAllocatedOrgsForJob?.allocated_organisation_names?.length
        ? this.getAllocatedOrgsForJob?.allocated_organisation_names?.join(", ")
        : null;
    },
    getNumberedAllocatedAgencies() {
      return this.getAllocatedOrgsForJob?.allocated_organisation_names?.length
        ? this.getAllocatedOrgsForJob?.allocated_organisation_names
            ?.map((val, index) => `${index + 1}. ` + val)
            .join("<br />")
        : null;
    },
    jobTitle() {
      return this.item?.job_title || "--";
    },
    id() {
      return `${this.item?.job_id}` || "--";
    },
    displayId() {
      return `${this.item?.display_uid}` || "--";
    },
    level() {
      return this.item.level_name ? this.item.level_name : "--";
    },
    organisation() {
      if (this.isCandidateSupplierFromAccessToken) {
        return this.item?.parent_org_info[0]?.name || "--";
      }
      return this.item?.organisation_name || "--";
    },
    location() {
      return this.item?.location_name || "--";
    },
    type() {
      return this.item?.candidate_type_name || "--";
    },
    speciality() {
      return this.item?.speciality_name || "--";
    },
    subSpeciality() {
      return this.item?.sub_speciality_name || "--";
    },
    featured() {
      return this.item?.featured || false;
    },
    other_areas_of_expertise() {
      return this.item?.other_areas_of_expertise || "--";
    },
    publishStatus() {
      if (this.item?.is_private_job) return "Draft";
      if (this.item?.status == "Active") return "Published";
      return this.item?.status;
    },
    expiryDays() {
      let days = 0;
      if (this.item.expiry_review_date) {
        let diff = moment(this.item.expiry_review_date).diff(moment(), "days");
        days = diff < 0 ? "Expired" : `${diff} Days`;
      }
      return days;
    },
    iconUrl() {
      if (this.isCandidateSupplierFromAccessToken) {
        return this.item.parent_org_info?.[0]?.logo_url || "/img/hospital.png";
      }
      return this.item?.organisation_logo_url || "/img/hospital.png";
    },
    totalAppliedCount() {
      return this.item?.counts?.applied_count || 0;
    },
    totalAppliedMatchedCount() {
      return this.item?.counts?.matched_applied_count || 0;
    },
    reviewCount() {
      return this.item?.counts?.review_count || 0;
    },
    inProcessCount() {
      return this.item?.counts?.in_process_count || 0;
    },
    matchedCount() {
      return this.item?.counts?.matched_count || 0;
    },
    agencyMatchedCount() {
      return this.item?.counts?.agency_matched_count || 0;
    },
    isNurse() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 2
        : false;
    },
    isAHP() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 3
        : false;
    },
    isNonClinicalAdmin() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 6
        : false;
    },
    jobAllocatedOn() {
      return this.item?.allocated_data[0]?.created_on || "--";
    },
    isJobCreatedByParent() {
      return this.item?.created_by_parent_org?.org_name || "";
    },
    publishedTo() {
      return this.item?.advertisement_location || [];
    },
    displayLocation() {
      return this.item?.display_location;
    },
  },
  methods: {
    ...mapActions(["selectedJobBoard", "fetchAllocatedOrganisations"]),
    showAllocatedAgencies(data) {
      this.showAllocatedOrg = !this.showAllocatedOrg;
      if (data.allocated_organisation_count < 1) return;
      let job_id = data?.job_id;
      if (job_id && this.showAllocatedOrg) {
        this.fetchAllocatedOrganisations({ job_id: job_id });
      }
    },
    navigateToJobListPreview(item) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${item?.job_id}` });
    },
    navigateToJob(item) {
      this.$router.push({ path: `/jobs-list?job_id=${item?.job_id}` });
    },
    previewJob(job) {
      this.selectedJobBoard(job.job_id);
      this.previewModal = true;
    },
    onCancelPreview() {
      this.previewModal = false;
    },
    formatDate(date) {
      return date ? moment(date).format("DD MMM YYYY") : "--";
    },
    openGroupList(item) {
      this.jobGroupModal = {
        ...this.jobGroupModal,
        isShowPopup: true,
        modalTitle: `${item.job_title} - ${item.display_uid} - Group`,
      };
    },
    jobGroupModalCallback() {
      this.jobGroupModal.isShowPopup = false;
    },
    closeModal() {
      this.jobGroupModal.isShowPopup = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.job-card-title {
  margin-bottom: 4px;
}
.table-card {
  text-align: center;
  min-width: 75px;
  max-width: 75px;
  padding: 3px;
  padding-top: 10px;
  height: 60px;
  margin-right: 2px;
  border-style: solid;
  border-width: thin;
  font-weight: 400;
  font-size: 12px;
  a {
    cursor: pointer;
    color: gray;
  }
}
.match-span {
  color: green;
}
.text-bold {
  font-weight: 700;
}
.icon-wrapper {
  .icon {
    width: 50px;
    img {
      width: 35px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.tooltip-css {
  tooltip {
    white-space: nowrap;
  }
}
.action-btn {
  width: 100%;
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
.text-gold {
  color: #ff7600;
}
.fs-10 {
  font-size: 10px !important;
}
.btn-link {
  cursor: pointer;
}
.ratio-1 {
  aspect-ratio: 1;
}
span.step {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: $white;
  display: inline-block;
  font-family: Poppins, sans-sarif !important;
  font-weight: 600;
  line-height: 1.55em;
  margin-right: 5px;
  text-align: center;
  width: 1.55em;
  opacity: 0.9;
}
.pointer {
  cursor: pointer;
}
</style>
<style lang="scss">
.allocated-agency .tooltip .tooltip-inner {
  max-height: 97px;
  overflow-y: auto;
  text-align: left;

  &::-webkit-scrollbar {
    width: 15px;
  }
}
.allocated-agency .tooltip.show {
  opacity: 1 !important;
}
.ad-loc .tooltip .tooltip-inner {
  max-height: 97px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 15px;
  }
}
</style>
