<template>
  <div>
    <div class="d-flex align-items-center flex-wrap filter dateInput">
      <div v-if="label" class="mb-2">{{ label }}</div>
      <v-select
        :name="`date-dropdown-date-${name}`"
        v-if="visiableFields('date')"
        :value="outputDd"
        @input="inputDate"
        :options="options['date']"
        :taggable="taggable"
        :multiple="multiple"
        :clearable="false"
        :disabled="disabled"
        :selectOnTab="true"
        class="mr-2 date-field-max"
      />
      <v-select
        v-if="visiableFields('month')"
        :name="`date-dropdown-month-${name}`"
        :value="outputMm"
        @input="inputMonth"
        :options="options['month']"
        :taggable="taggable"
        :multiple="multiple"
        :clearable="false"
        :disabled="disabled"
        :selectOnTab="true"
        class="mr-2 month-field-max"
      />
      <v-select
        v-else-if="visiableFields('year')"
        :name="`date-dropdown-year-${name}`"
        :value="outputYy"
        @input="inputYear"
        :options="options['year']"
        :taggable="taggable"
        :multiple="multiple"
        :clearable="false"
        :disabled="disabled"
        :selectOnTab="true"
        :futureYear="futureYear"
        :numberOfYears="numberOfYears"
        :startYear="startYear"
        class="year-field-max"
      />
    </div>
  </div>
</template>

<script>
import m from "moment";
import { times } from "lodash";

export default {
  name: "DateInput",
  props: {
    name: {
      type: String,
      default: "DateInput",
    },
    futureYear: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Date, Array],
      default: null,
    },
    label: {
      type: String,
      default: undefined,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputFields: {
      type: Array,
      default:()=> ["date", "month", "year"],
    },
    numberOfYears: {
      type: Number,
      default: 50
    },
    startYear: {
      type: Number,
      default: function () {
        return this.currentYear;
      },
    },
  },
  data() {
    return {
      outputDd: null,
      outputMm: null,
      outputYy: null,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    momentDate() {
      return this.value && this.value.length && m(this.value[0])
        ? m(this.value[0])
        : null;
    },
    dd() {
      return this.momentDate
        ? {
            code: this.pad(this.momentDate.date(), 2),
            label: this.pad(this.momentDate.date(), 2),
          }
        : null;
    },
    mm() {
      return this.momentDate
        ? {
            code: this.pad(this.momentDate.month() + 1, 2),
            label: m.monthsShort()[this.momentDate.month()],
          }
        : null;
    },
    yy() {
      return this.momentDate
        ? { code: this.momentDate.year(), label: this.momentDate.year() }
        : null;
    },
    options() {
      const pad = this.pad;

      return {
        date: times(31, (i) => {
          return { code: pad(i + 1, 2), label: pad(i + 1, 2) };
        }),
        month: times(12, (i) => {
          return { code: pad(i + 1, 2), label: m.monthsShort()[i].toString() };
        }),
        year: times(this.numberOfYears, (i) => {
          if (this.futureYear) {
            return { code: this.startYear + i, label: (this.startYear + i).toString() };
          }
          return { code: this.startYear - i, label: (this.startYear - i).toString() }; 
        }),
      };
    },
    outputDate() {
      let d = "";
      if(this.visiableFields("year")){
        d += this.outputYy && this.outputYy.code ? this.outputYy.code + "-" :""
      }
      if(this.visiableFields("month")){
        d += (this.outputMm && this.outputMm.code ? this.outputMm.code+"-" : "")
      }
      if(this.visiableFields("date")){
        d += d + (this.outputDd && this.outputDd.code ? this.outputDd.code : "");
      }
      d = d.replace(/-\s*$/, "");
      return d;
    },
  },  
  mounted() {
    this.DateValue();
  },
  watch: {
    value() {
      if (!this.value) {
        this.resetDate();
      } else {
        this.DateValue();
      }
    },
  },
  methods: {
    visiableFields(field) {
      return this.inputFields.length ? this.inputFields.includes(field) : true;
    },
    inputDate(value) {
      this.outputDd = value ? value : null;
      this.change();
    },
    inputMonth(value) {
      this.outputMm = value ? value : null;
      this.change();
    },
    inputYear(value) {
      this.outputYy = value ? value : null;
      this.change();
    },
    change() {
      if (this.outputDate) {
        this.$emit("input", this.name, this.outputDate);
        this.$emit("change", this.name, this.outputDate);
      }
    },
    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },
    resetDate() {
      this.outputDd = null;
      this.outputMm = null;
      this.outputYy = null;
    },
    DateValue() {
      (this.outputDd = this.dd),
        (this.outputMm = this.mm),
        (this.outputYy = this.yy);
    },
  },
};
</script>
<style lang="scss">
.has-error {
  color: $red;
}
.is-danger {
  .vs__dropdown-toggle {
    border-color: $red;
    box-shadow: none;
    outline: none;
  }
}

.separator {
  opacity: 0.5;
}
.dateInput {
  .date-field,
  .month-field,
  .year-field {
    min-width: 100px;
    max-width: 120px;
  }
  .date-field-max,
  .month-field-max,
  .year-field-max {
    min-width: 100px;
    max-width: 120px;
  }
}
</style>
