<template>
  <div>
    <div class="mb-2">{{ label }}</div>
    <treeselect
      :name="name"
      :multiple="multiple"
      :options="treeOptions"
      :value="treeValue"
      :showCount="showCount"
      :clearable="clearable"
      :disabled="disabled"
      @change="handleChange"
      @input="handleChange"
    />
  </div>
</template>

<script>
import Treeselect from "@mail2gowthamganesan/techno-vue-treeselect";
import "@mail2gowthamganesan/techno-vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "filters",
  components: {
    Treeselect,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array, Number, Boolean],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedValues: [],
    };
  },
  computed: {
    treeValue() {
      const res = this.value.map((v) => v?.id || v?.code || v) || [];
      return res;
    },
    treeOptions() {
      const options = this.options.filter(options => options.code !=='all');
      const opt = this.convertOptionToTreeOption(options) || [];
      return this.showAll ? [{ id: "all", label: "All", children: opt }] : opt;
    },
  },
  methods: {
    handleChange(value) {
      this.$emit("input", this.name, value);
    },
    convertOptionToTreeOption(value) {
      return value.map((v) => {
        return {
          id: v.code || v,
          ...v,
        };
      });
    },
  },
};
</script>
