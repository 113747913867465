<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <button aria-label="Close" class="close" @click="closeModal">x</button>
      </template>
      <template #footer>
        <CButton color="secondary" @click="closeModal">Close</CButton>
      </template>
      <CDataTable
        :striped="true"
        :bordered="true"
        :small="true"
        :items="groupList"
        :fields="fields"
        pagination
        :items-per-page="10"
        class="p-2"
      >
        <template #action="{ item }">
          <td>
            <CButton
              color="secondary"
              v-if="
                jobData.group_ids && jobData.group_ids.includes(item.group_id)
              "
              @click="removeJobFromGroup(item.group_id, jobData)"
            >
              Remove
            </CButton>
            <CButton
              color="primary"
              v-else
              @click="addJobToGroup(item.group_id, jobData)"
            >
              Add to Group
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CModal>
  </div>
</template>

<script>
import { set } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "JobGroupModal",
  props: ["modalTitle", "modalColor", "isShowPopup", "closeModal", "jobData"],
  data() {
    return {
      fields: [
        { key: "group_name", label: "Group", _style: "width:80%" },
        { key: "action", label: "Action", _style: "width:20%" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getJobGroups"]),
    groupList() {
      return this.getJobGroups;
    },
  },
  methods: {
    ...mapActions(["showToast", "updateJobByDetailPage"]),
    addJobToGroup(group_id, job) {
      let group_ids = job.group_ids || [];
      group_ids.push(group_id);
      group_ids.filter((item, index) => group_ids.indexOf(item) === index);
      let payload = {
        job_id: job.job_id,
        group_ids: group_ids,
        customer_uid: job.customer_uid,
        organisation_id: job.organisation_uid,
      };
      this.updateJobByDetailPage(payload);
    },
    removeJobFromGroup(group_id, job) {
      let group_ids = job.group_ids || [];
      group_ids = group_ids.filter((item) => item !== group_id);
      let payload = {
        job_id: job.job_id,
        group_ids: group_ids,
        customer_uid: job.customer_uid,
        organisation_id: job.organisation_uid,
      };
      this.updateJobByDetailPage(payload);
    },
  },
};
</script>
