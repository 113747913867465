<template>
  <div>
    <div v-if="label" class="mb-2">{{ label }}</div>
    <v-select
      :name="name"
      :value="selectedValueCustom"
      :placeholder="placeholderCheck"
      @input="input"
      :label="option_label"
      :options="options"
      :taggable="taggable"
      :multiple="multiple"
      :clearable="clearable"
      :disabled="disabled"
      :class="{ 'is-danger': error }"
      @search="handleFetchOption"
    />
    <small class="has-error" v-if="error">{{ error }}</small>
  </div>
</template>

<script>
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import { mapGetters } from "vuex";

export default {
  name: "Select",
  props: {
    name: {
      type: String,
      default: "SelectBox",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: [Object, String, Number, Array],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: undefined,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fetchOptions: {
      type: Function,
      default: () => {
        console.log("fetchOptions");
      },
    },
    option_label: {
      type: String,
      default: undefined,
    },
    enableNA: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    autoPopulate: {
      type: Boolean,
      default: false,
    },
  },
  // @Todo commented due to some issues don't remove below code, once stable will remove the code
  // watch: {
  //   options(newOptions, oldOptions) {
  //     if (
  //       this.options &&
  //       this.options.find &&
  //       this.value &&
  //       oldOptions.length > 0
  //     ) {
  //       const { code, label } = this.value;
  //       const result = this.options.find(
  //         o => o.code === code && o.label === label
  //       );
  //       if (!result && !this.multiple) {
  //         this.$emit("change", this.name, null);
  //         this.$emit("input", this.name, null);
  //       }
  //     }
  //   }
  // },
  computed: {
    ...mapGetters(["isJobSupplierFromAccessToken", "isUKMainAgency"]),
    selectedValueCustom() {
      if (isObject(this.value) && isEmptyObjectCheck(this.value)) {
        return [];
      }
      return this.value;
    },
    placeholderCheck() {
      return this.enableNA && this.disabled ? "N/A" : this.placeholder;
    },
  },
  methods: {
    input(value) {
      // for pertemps
      if (this.isUKMainAgency || this.isJobSupplierFromAccessToken) {
        // for pertemps Partner Agency Filter
        if (this.name == "organisation") {
          // Partner Agency(organisation) select
          let last_index = value[value.length - 1];
          let val = null;
          if (last_index?.label == "All") {
            this.$emit("change", this.name, [{ code: 0, label: "All" }]);
            this.$emit("input", this.name, [{ code: 0, label: "All" }]);
          } else if (!last_index) {
            this.$emit("change", this.name, val);
            this.$emit("input", this.name, val);
          } else {
            if (value[0].label == "All") value = value.slice(1);
            val = value.map((v) => {
              return v.id || v.code || value;
            });
            this.$emit("change", this.name, val);
            this.$emit("input", this.name, val);
          }
        } else {
          this.$emit("change", this.name, value);
          this.$emit("input", this.name, value);
        }
      } else {
        this.$emit("change", this.name, value);
        this.$emit("input", this.name, value);
      }
    },
    handleFetchOption(search, loading) {
      this.fetchOptions(search, loading, this.name);
    },
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (
          !this.disabled &&
          this.options.length === 1 &&
          this.selectedValueCustom.length === 0 &&
          this.autoPopulate
        ) {
          const first_opt = this.options[0];
          this.input([first_opt]);
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.has-error {
  color: $red;
}
.is-danger {
  .vs__dropdown-toggle {
    border-color: $red;
    box-shadow: none;
    outline: none;
  }
}
</style>
