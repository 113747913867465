<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="toggleSideBar" v-if="show"></div>
    <transition :name="slideSide">
      <div
        v-show="show"
        class="sidebar-panel"
        :style="[positionLeft ? { left: '0' } : { right: '0' }]"
      >
        <slot name="container">
          <div class="sidebar-pannel-container resizable-y">
            <slot name="header-wrapper">
              <header class="sidebar-panel-header">
                <slot name="header">
                  <p>header</p>
                </slot>
              </header>
            </slot>
            <div class="resizer-y"></div>
            <slot name="body-wrapper">
              <div class="sidebar-panel-body">
                <slot></slot>
              </div>
            </slot>
            <!-- <slot name="footer-wrapper">
              <footer class="sidebar-panel-footer">
                <slot name="footer">
                  <h5>footer</h5>
                </slot>
              </footer>
            </slot> -->
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    positionLeft: { type: Boolean, default: false },
    ["show"]: Boolean,
    ["toggleSideBar"]: Function,
  },
  data: function () {
    return {
      formattedSelectedFilters: {},
      resizer: null,
    };
  },
  watch: {
    show(val) {
      if (val == true) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("body")[0].style.overflow = "";
      }
    },
    selectedFilters: {
      handler: function (val) {
        this.formattedSelectedFilters = Object.keys(val)
          .filter((key) => val[key] && val[key].length)
          .map((flKey) => {
            return {
              [flKey]: val[flKey],
            };
          });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["selectedFilters"]),
    slideSide() {
      if (this.positionLeft) {
        return "slideLeft";
      }
      return "slideRight";
    },
  },
  methods: {
    ...mapActions(["updateSelectedFilter"]),
    removeFilter(type, val) {
      const filterToBeUpdated = type[Object.keys(type)[0]].filter(
        (item) => item.code !== val.code
      );

      this.updateSelectedFilter({
        name: Object.keys(type)[0],
        value: filterToBeUpdated,
      });
    },
    onmousedown(e) {
      e.preventDefault();
      const resizer = document.querySelector(".resizer-y");
      document.addEventListener("mousemove", this.onmousemove);
      document.addEventListener("mouseup", this.onmouseup);
    },
    ontouchstart(e) {
      e.preventDefault();
      const resizer = document.querySelector(".resizer-y");
      resizer.addEventListener("touchmove", this.ontouchmove);
      resizer.addEventListener("touchend", this.ontouchend);
    },
    onmousemove(e) {
      e.preventDefault();
      const resizer = document.querySelector(".resizer-y");
      const clientY = e.clientY;
      const deltaY = clientY - (resizer._clientY || clientY);
      resizer._clientY = clientY;
      const t = document.getElementById("selectedSection");
      const b = resizer.nextElementSibling;
      // UP
      if (deltaY < 0) {
        const minHeight = parseInt(getComputedStyle(t).minHeight);
        const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
        t.style.height = `${h < minHeight ? minHeight : h}px`;
      }
      // DOWN
      if (deltaY > 0) {
        const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
        t.style.height = `${h < 10 ? 0 : h}px`;
      }
    },
    onmouseup(e) {
      e.preventDefault();
      const resizer = document.querySelector(".resizer-y");
      document.removeEventListener("mousemove", this.onmousemove);
      document.removeEventListener("mouseup", this.onmouseup);
      delete e._clientY;
    },
    ontouchmove(e) {
      e.preventDefault();
      const resizer = document.querySelector(".resizer-y");
      const clientY = e.touches[0].clientY;
      const deltaY = clientY - (resizer._clientY || clientY);
      resizer._clientY = clientY;
      const t = document.getElementById("selectedSection");
      const b = resizer.nextElementSibling;
      // UP
      if (deltaY < 0) {
        const minHeight = parseInt(getComputedStyle(t).minHeight);
        const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
        t.style.height = `${h < minHeight ? minHeight : h}px`;
      }
      // DOWN
      if (deltaY > 0) {
        const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
        t.style.height = `${h < 10 ? 0 : h}px`;
      }
    },
    ontouchend(e) {
      e.preventDefault();
      const resizer = document.querySelector(".resizer-y");
      resizer.removeEventListener("touchmove", ontouchmove);
      resizer.removeEventListener("touchend", ontouchend);
      delete e._clientY;
    },
  },
  watch: {
    show(newVal) {
      const resizer = document.querySelector(".resizer-y");
      if (resizer) {
        if (newVal) {
          resizer.addEventListener("mousedown", this.onmousedown);
          resizer.addEventListener("touchstart", this.ontouchstart);
        } else {
          resizer.removeEventListener("mousedown", this.onmousedown);
          resizer.removeEventListener("touchstart", this.ontouchstart);
        }
      }
    },
  },
};
</script>
