<template>
  <div>
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <button aria-label="Close" class="close" @click="closeModal">x</button>
      </template>
      <template #footer>
        <CButton
          type="button"
          color="secondary"
          class="px-4"
          @click="closeModal"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Submit</CButton
        >
      </template>
      <ValidationObserver ref="addGroupFilter" v-slot="{ handleSubmit }">
        <form id="addGroupFilter" @submit.prevent="handleSubmit(onSubmit)">
          <CRow class="row mb-2">
            <label class="required col-lg-3 col-md-12">Group Name</label>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <TextInput
                  name="group_name"
                  :value="addGroupFilter.group_name"
                  @input="handleInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </div>
          </CRow>
        </form>
      </ValidationObserver>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import TextInput from "@/components/reusable/Fields/TextInput";
import { mapActions, mapGetters } from "vuex";

extend("required", { ...required, message: "This field is required" });

export default {
  name: "AddGroupModal",
  components: {
    TextInput,
  },
  props: [
    "modalTitle",
    "modalColor",
    "isShowPopup",
    "closeModal",
    "isJobGroupEdit",
    "group_name",
    "group_id",
  ],
  data() {
    return {
      addGroupFilter: {},
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getOrgIDFromAccessToken",
      "isUKMainAgency",
      "getIsParentUserFromAccessToken",
    ]),
  },

  mounted() {
    if (this.isJobGroupEdit) {
      this.handleInput("group_name", this.group_name);
    }
  },
  methods: {
    ...mapActions(["showToast", "createJobGroup", "updateJobGroup"]),

    handleInput(name, value) {
      Vue.set(this.addGroupFilter, name, value);
    },
    async onSubmit() {
      this.addGroupFilter = {
        ...this.addGroupFilter,
        customer_uid: this.getCustomerId,
      };
      if (!this.isUKMainAgency && !this.getIsParentUserFromAccessToken) {
        this.addGroupFilter.organisation_id = this.getOrgIDFromAccessToken;
      }
      if (!this.isJobGroupEdit) {
        this.addGroup(this.addGroupFilter);
      } else {
        this.updateGroup(this.addGroupFilter);
      }
    },
    addGroup(data) {
      this.createJobGroup(data);
      this.closeModal();
    },
    updateGroup(payload) {
      this.updateJobGroup({
        ...payload,
        group_id: this.group_id,
      });
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.bookmark-url {
  word-break: break-all;
}
</style>
