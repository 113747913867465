<template>
  <div class="generic-pagination row mx-0" :class="alignment">
    <div class="d-flex mr-3 align-items-center mt-1 mb-1" v-if="showPageNumber">
      <label class="text-muted mb-0 mx-1">Page No:</label>
      <input
        type="number"
        aria-label="Page No"
        class="form-control d-flex page-number-input"
        :value="currentPage"
        min="1"
        :max="totalPages"
        :disabled="totalPages === 1"
        @input="handleInput($event.target.value)"
      />
    </div>
    <ul class="pagination mt-1 mb-1">
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
          class="text-primary page-link text-nowrap"
          aria-label="Go to first page"
        >
          &#60;&#60;
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
          class="text-primary page-link text-nowrap"
          aria-label="Go to previous page"
        >
          &#60; Prev
        </button>
      </li>

      <li v-for="(page, index) in pages" class="pagination-item" :key="index">
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          :aria-label="`Go to page number ${page.name}`"
          class="text-primary page-link page-number"
        >
          {{ page.name }}
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
          class="text-primary page-link text-nowrap"
          aria-label="Go to next page"
        >
          Next &#62;
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickLastPage"
          :disabled="isInLastPage"
          class="text-primary page-link text-nowrap"
          aria-label="Go to last page"
        >
          &#62;&#62;
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    position: {
      type: String,
      default: "right",
    },
    showPageNumber: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    alignment() {
      let alignment_class = "justify-content-end";
      switch (this.position.toLowerCase()) {
        case "left":
          alignment_class = "justify-content-start";
          break;
        case "center":
          alignment_class = "justify-content-center";
          break;
        case "right":
          alignment_class = "justify-content-end";
          break;
        default:
          alignment_class = "justify-content-end";
          break;
      }
      return alignment_class;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage) || 1;
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (
        this.currentPage === this.totalPages ||
        this.currentPage + this.maxVisibleButtons > this.totalPages
      ) {
        const startPage = this.totalPages - this.maxVisibleButtons + 1;
        return startPage > 0 ? startPage : 1;
      }
      return this.currentPage;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("pagechanged", value);
    },
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
  mounted() {
    this.currentPage = parseInt(this.$router.currentRoute?.query?.page || 1);
  },
  watch: {
    "$route.query": function (query) {
      const pageNo = query.page || 1;
      this.currentPage = parseInt(pageNo);
    },
  },
};
</script>